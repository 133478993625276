import React from "react";

const HomeInfo = () => {
  return (
    <div className="mx-6">
      <h2
        className="title align is-center has-text-centered mt-4"
        style={{
          fontSize: "3rem",
        }}
      >
        EXPLÍCANOS TUS NECESIDADES, TENEMOS UNA SOLUCIÓN
      </h2>
      <h3
        className="title align is-center has-text-centered"
        style={{
          fontSize: "1.5rem",
        }}
      >
        Dedícate a tu negocio, nosotros haremos el resto
      </h3>
      <div className="columns align is-ce has-text-centered">
        <div className="column card_home_info">
          <h4 className="has-text-weight-semibold mb-3">TRANSPORTE</h4>
          <img
            src="https://images.pexels.com/photos/60671/pexels-photo-60671.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="transporte_img"
            className="img_home_info"
          />
          <p>
            Transporte nacional e internacional: Proporcionamos servicios
            nacionales e internacionales de transporte de mercancías por
            carretera, con máxima profesionalidad, puntualidad y seguridad a
            precios altamente competitivos.
          </p>
        </div>
        <div className="column card_home_info">
          <h4 className="has-text-weight-semibold mb-3">LOGÍSTICA</h4>
          <img
            src="https://images.pexels.com/photos/60671/pexels-photo-60671.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="logistica_img"
            className="img_home_info"
          />

          <p>
            Logística es la organización y el control de los flujos de
            mercancías. Nuestra misión es mejorar constantemente el flujo de
            productos para nuestros clientes.
          </p>
        </div>
      </div>
      <div className="box m-4 quote-box">
        <div>
          <div className="card-icon">
            <img src="http://cabasasl.es/wp-content/uploads/2018/06/logo.png"></img>
          </div>
          <p>
            A lo largo de nuestros años de experiencia, nos hemos ido decantando
            por diversos sectores del mercado europeo que precisa de servicios
            muy especializados desde el punto de vista del transporte. Durante
            todo este tiempo hemos realizado un extraordinario trabajo gracias a
            nuestra oferta de multi-servicios tanto en el ámbito nacional como
            internacional. Fruto de nuestro trabajo alcanzamos importantes
            objetivos para beneficiar ante todo a nuestros clientes.
          </p>
          <div className="quote">
            <p className="quote-name">J. Antonio Bautista</p>
            <p className="quote-job has-text-info">
              Director, Transportes Cabasa
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeInfo;
