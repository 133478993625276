import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            ERROR
        </div>
    );
}

export default NotFoundPage;
