import React from 'react';
import Slider from '../../components/pure/Slider';
import HomeInfo from '../../components/pure/HomeInfo';

const HomePage = () => {
    return (
      <div className='mb-5'>
        <div className="container">
          <div>
            <h2
              className="subtitle align is-center has-text-centered mb-4 has-text-weight-semibold"
              style={{
                fontSize: "3rem",
              }}
            >
              BIENVENIDOS A
            </h2>
            <h1
              className="title align is-center has-text-centered mb-4 has-text-weight-bold"
              style={{
                fontSize: "5rem",
              }}
            >
              TRANSPORTES CABASA
            </h1>
          </div>
        </div>
        <div className="box m-4">
          <Slider />
        </div>
        <div>
          <HomeInfo />
        </div>
      </div>
    );
}

export default HomePage;
