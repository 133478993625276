/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/404/NotFoundPage";
import GalleryPage from "./pages/gallery/GalleyPage";
import ContactPage from "./pages/contact/ContactPage";
import HomePage from "./pages/home/HomePage";

import './styles/styles.css';
import NavBar from "./components/pure/NavBar";
import FooterComp from "./components/pure/FooterComp";

function App() {


  return (
    <Router>
      <NavBar />

      <main className="main-page">
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/gallery" element={<GalleryPage />} />
          <Route exact path="/contact" element={<ContactPage />} />

          <Route component={NotFoundPage} />
        </Routes>
      </main>
      <FooterComp />
    </Router>
  );
}

export default App;
