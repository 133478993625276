import React from 'react';
import GalleryComp from '../../components/pure/GalleryComp';

const GalleyPage = () => {
    return (
        <div className='mb-5'>
            <GalleryComp />
        </div>
    );
}

export default GalleyPage;
