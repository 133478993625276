import React from "react";
import { useState } from "react";
import { Alert } from "@mui/material";
import '../../styles/contact.css'

const ContactPage = () => {
  const [nombre, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [asunto, setAsunto] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [msgSuccess, setmsgSuccess] = useState(false);
  const [msgError, setmsgError] = useState(false);

  async function registerUser(event) {
    event.preventDefault();

    try {
      const response = await fetch("http://localhost:5000/api/mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombre,
          email,
          telefono,
          asunto,
          mensaje,
        }),
      });

      const data = await response.json();

      msgSent();
      setName("");
      setEmail("");
      setTelefono("");
      setAsunto("");
      setMensaje("");
      console.log(data);
    } catch (error) {
      console.log(error);
      msgFailed()
    }
  }

  async function msgSent() {
    setmsgSuccess(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setmsgSuccess(false);
  }

  async function msgFailed() {
    setmsgError(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setmsgError(false);
  }

  return (
    <div className="mb-5 contact-card">
      <div className="container card contacto-page has-background-grey has-text-primary-light	">
        <h2 className="title has-text-white	 align is-center has-text-centered mb-4 pt-3 has-text-weight-semibold">
          CONTACTO
        </h2>
        <div className="mx-5 my-2 columns">
          <form onSubmit={registerUser} className="control column">
            <div className="field">
              <label className="label mb-0 mt-1">Nombre</label>
              <div className="control">
                <input
                  value={nombre}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Nombre"
                  className="input"
                />
              </div>
            </div>
            <div className="field">
              <label className="label mb-0 mt-1">Email</label>
              <div className="control">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                  className="input"
                />
              </div>
            </div>
            <div className="field">
              <label className="label mb-0 mt-1">Teléfono</label>
              <div className="control">
                <input
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  type="number"
                  placeholder="Telefono"
                  className="input"
                />
              </div>
            </div>
            <div className="field">
              <label className="label mb-0 mt-1">Asunto</label>
              <div className="control">
                <input
                  value={asunto}
                  onChange={(e) => setAsunto(e.target.value)}
                  type="text"
                  placeholder="Asunto"
                  className="input"
                />
              </div>
            </div>
            <div className="field">
              <label className="label mb-0 mt-1">Mensaje</label>
              <div className="control">
                <input
                  value={mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                  type="text"
                  placeholder="Mensaje"
                  className="input"
                />
              </div>
            </div>
            <input
              type="submit"
              value="Enviar"
              className="button is-right is-link"
            />
          </form>
        </div>
      </div>

      {msgSuccess ? (
        <Alert severity="success">El mensaje se ha enviado correctamente</Alert>
      ) : null}
      {msgError ? (
        <Alert severity="error">
          ¡Vaya! Parece que ha habido un error al enviar tu mensaje
        </Alert>
      ) : null}
    </div>
  );
};

export default ContactPage;
