import React from 'react';
import "../../styles/gallery.css";


const GalleryComp = () => {
    const images = [
      {
        src: "https://images.pexels.com/photos/17289370/pexels-photo-17289370/free-photo-of-naturaleza-campo-flores-verano.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load",
        alt: "Image 1",
      },
      {
        src: "https://images.pexels.com/photos/17029842/pexels-photo-17029842/free-photo-of-panorama-urbano-oceano-verano-costa.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
        alt: "Image 2",
      },
      {
        src: "https://images.pexels.com/photos/15272405/pexels-photo-15272405/free-photo-of-mar-naturaleza-oceano-rocoso.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
        alt: "Image 3",
      },
      {
        src: "https://images.pexels.com/photos/16741654/pexels-photo-16741654/free-photo-of-mar-paisaje-hombre-playa.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
        alt: "Image 4",
      },
      {
        src: "https://images.pexels.com/photos/12238171/pexels-photo-12238171.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
        alt: "Image 5",
      },
    ];
    return (
      <div className="gallery mx-3">
        {images.map((image, index) => (
          <img
            key={index}
            className="gallery__item"
            src={image.src}
            alt={image.alt}
          />
        ))}
      </div>
    );
}

export default GalleryComp;
