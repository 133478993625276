import React from "react";

const FooterComp = () => {
  return (
    <footer className="has-background-dark columns">
      <div className="column has-text-centered container">
        <h4 className="has-text-info title has-text-weight-semibold">
          Datos de Contacto
        </h4>
        <div className="has-text-light">
          <p>Transportes Caro Bautista y Sánchez,S.L.</p>
          <p>Calle Peseta, parcela 9.16</p>
          <p>11591-Jerez de la Frontera</p>
          <br />
          <p>Tfno. +34 956 157 929</p>
          <p>Fax +34 956 285 451</p>
          <br />
          <p>info@cabasasl.es</p>
        </div>
      </div>
      <div className="column has-text-centered">
        <h4 className="has-text-info title has-text-weight-semibold">
          Dónde estamos
        </h4>
        <iframe
          title="ubicacion"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3198.021216994754!2d-6.0970701892341355!3d36.72205167215394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd0de948015f2307%3A0x26d8942fddccb793!2sCaro%20Bautista%20Y%20S%C3%A1nchez%20S.%20L.!5e0!3m2!1ses!2ses!4v1691504696916!5m2!1ses!2ses"
          width="400"
          height="200"
          style={{
            borderRadius: "25px",
          }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </footer>
  );
};

export default FooterComp;
