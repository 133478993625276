/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from "react-router-dom";

import { useState } from "react";


const NavBar = () => {
       const [isActive, setisActive] = useState(false);

    return (
      
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a href="/" className="navbar-item">
              <img
                src="https://www.cabasasl.es/wp-content/uploads/2018/09/logo_horizontal_cabasa.jpg"
                alt="Logo"
              />
            </a>

            <a
              role="button"
              onClick={() => {
                setisActive(!isActive);
              }}
              className={"navbar-burger burger"}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div
            id="navbarBasicExample"
            className={`navbar-menu ${isActive ? "is-active" : ""}`}
          >
            <div className="navbar-end">
              <div className="navbar-item">
                <Link to={"/"} className="navbar-item">
                  Inicio
                </Link>
                <Link to={"/gallery"} className="navbar-item">
                  Galería
                </Link>
                <Link to={"/contact"} className="navbar-item">
                  Contacto
                </Link>
              </div>
            </div>
          </div>
        </nav>
      
    );
}

export default NavBar;
